var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content-body pb-8"
  }, [_c('div', {
    staticClass: "space-y-4"
  }, [_c('div', {
    staticClass: "flex justify-between items-center"
  }, [_c('div', {
    staticClass: "flex items-center gap-4"
  }, [_c('ArrowForward', {
    staticClass: "cursor-pointer",
    attrs: {
      "direction": "left"
    },
    on: {
      "handleClick": function handleClick($event) {
        return _vm.goBack();
      }
    }
  }), _c('h1', {
    staticClass: "text-3xl font-bold"
  }, [_vm._v(_vm._s(_vm.pageName))])], 1)]), _vm.pageLoading ? _c('div', {
    staticClass: "loading-page"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Fetching Data ..."
    }
  })], 1) : _c('div', {
    staticClass: "space-y-4"
  }, [_c('div', {
    staticClass: "shadow-small card py-4 bg-white rounded-lg px-6"
  }, [_c('div', {
    staticClass: "space-y-4"
  }, [_c('div', {
    staticClass: "space-y-1"
  }, [_c('h1', {
    staticClass: "text-base text-neutral font-bold"
  }, [_vm._v(" " + _vm._s(_vm.employeeName) + " ")]), _c('p', {
    staticClass: "text-xs text-neutral-400 font-semibold"
  }, [_vm._v(_vm._s(_vm.departmentName))])])])]), _vm.leaveBalanceList.length > 0 ? _c('div', [_c('div', {
    staticClass: "flex gap-2 ml-5"
  }, _vm._l(_vm.tabOptions, function (tab, indexTab) {
    return _c('div', {
      key: tab.value + indexTab,
      staticClass: "tab__item text-sm px-5 py-2 rounded-tr-lg rounded-tl-lg cursor-pointer",
      class: [_vm.tabSelected.value === tab.value ? 'font-bold bg-white' : 'bg-neutral-50'],
      on: {
        "click": function click($event) {
          return _vm.selectTab(tab);
        }
      }
    }, [_vm._v(" " + _vm._s(tab.label) + " ")]);
  }), 0), _c('div', {
    staticClass: "shadow-small card py-8 bg-white rounded-lg px-6"
  }, [_vm.isFetchingData ? _c('div', {
    staticClass: "py-20"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "medium",
      "message": "Fetching Data ..."
    }
  })], 1) : _c('LeaveTable', {
    attrs: {
      "columns": _vm.columnsTable,
      "list": _vm.leaveBalanceList,
      "compact": ""
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(_ref) {
        var entry = _ref.entry,
            column = _ref.column;
        return [column.label === 'Action' ? _c('div', {
          staticClass: "flex justify-end gap-3 mr-4"
        }, [_c('Button', {
          attrs: {
            "size": "icon",
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/Pencil');
            },
            "tooltip": {
              show: true,
              text: 'Edit'
            }
          },
          on: {
            "action": function action($event) {
              return _vm.showModal(entry);
            }
          }
        })], 1) : column.label === 'Description' ? _c('div', {
          staticClass: "truncate max-w-xs"
        }, [_vm._v(" " + _vm._s(entry[column.field]) + " ")]) : _c('div', [_vm._v(_vm._s(entry[column.field]))])];
      }
    }], null, false, 3726233720)
  })], 1)]) : _c('div', {
    staticClass: "shadow-small card py-20 bg-white rounded-lg px-6"
  }, [_vm._m(0)])])]), _c('Modal', {
    attrs: {
      "modalVisible": _vm.isEditModalVisible
    },
    on: {
      "close": function close($event) {
        return _vm.toggleModal();
      }
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('div', {
    staticClass: "text-left my-2"
  }, [_c('h1', {
    staticClass: "text-xl font-bold mb-3"
  }, [_vm._v("Modify Balance")]), _c('div', {
    staticClass: "space-y-3 mb-5"
  }, [_c('TextField', {
    attrs: {
      "label": "Leave Type",
      "type": "text",
      "borderEnabled": "",
      "disabled": ""
    },
    model: {
      value: _vm.leaveBalanceSelected.leave_type_name,
      callback: function callback($$v) {
        _vm.$set(_vm.leaveBalanceSelected, "leave_type_name", $$v);
      },
      expression: "leaveBalanceSelected.leave_type_name"
    }
  }), _c('TextField', {
    attrs: {
      "label": "Total Leave",
      "type": "number",
      "borderEnabled": "",
      "placeholder": "Enter total leave",
      "error": _vm.errorForm['totalLeave'],
      "isLabelRequire": ""
    },
    model: {
      value: _vm.leaveBalanceSelected.leave_type_balance,
      callback: function callback($$v) {
        _vm.$set(_vm.leaveBalanceSelected, "leave_type_balance", $$v);
      },
      expression: "leaveBalanceSelected.leave_type_balance"
    }
  }), _c('TextField', {
    attrs: {
      "label": "Start Date",
      "type": "date",
      "borderEnabled": "",
      "disabled": ""
    },
    model: {
      value: _vm.leaveBalanceSelected.start_date,
      callback: function callback($$v) {
        _vm.$set(_vm.leaveBalanceSelected, "start_date", $$v);
      },
      expression: "leaveBalanceSelected.start_date"
    }
  }), _c('TextField', {
    attrs: {
      "label": "Expiration",
      "type": "date",
      "borderEnabled": "",
      "isStayShow": "",
      "error": _vm.errorForm['expiration']
    },
    model: {
      value: _vm.leaveBalanceSelected.end_date,
      callback: function callback($$v) {
        _vm.$set(_vm.leaveBalanceSelected, "end_date", $$v);
      },
      expression: "leaveBalanceSelected.end_date"
    }
  }), _c('TextArea', {
    staticClass: "mx-1",
    attrs: {
      "label": "Description",
      "isLabelRequire": "",
      "height": "h-28",
      "borderEnabled": ""
    },
    model: {
      value: _vm.leaveBalanceSelected.description,
      callback: function callback($$v) {
        _vm.$set(_vm.leaveBalanceSelected, "description", $$v);
      },
      expression: "leaveBalanceSelected.description"
    }
  })], 1), _c('div', {
    staticClass: "flex justify-end items-center gap-4"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Cancel",
      "type": "tertiary"
    },
    on: {
      "action": function action($event) {
        return _vm.toggleModal();
      }
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Confirm",
      "type": "primary",
      "disabled": !_vm.isEditFormValid
    },
    on: {
      "action": function action($event) {
        return _vm.confirmModify();
      }
    }
  })], 1)])])], 2)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('img', {
    staticClass: "mx-auto mt-0",
    attrs: {
      "src": require("@/assets/images/empty-content.svg"),
      "alt": "empty"
    }
  }), _c('div', {
    staticClass: "font-medium text-xl py-5"
  }, [_vm._v("Data is Not Available")])]);
}]

export { render, staticRenderFns }